import Cookies from 'cookies';

import { DEFAULT_LOCATION, DEFAULT_ZIPCODE, DEFAULT_ZONE_ID } from '../constants/default';
import * as COOKIE_VARIABLES from '../constants/cookieVariables';
import { isBestSellerApplicable, isDealsMerchandisingApplicable } from '../constants/feature';
import { ASAP, SCHEDULE } from '../constants/deliveryType';
import * as WEBSITES from '../constants/website';

import axios from '../utils/ajax';

import categoriesHelper, { getCategoryUrl } from './categoriesHelper';
import generateFilters from './generateFilters';
import getDefaultTab from './getDefaultTab';

const fetchServerResponse = async ({ url, method, headers, defaultResponse = {}, throwError = false }) => {
  try {
    const response = await axios({
      url,
      method,
      headers
    });
    return response;
  } catch (error) {
    console.error('shop.js_individual_getServerSideProps_catch', url, error);

    if (error && error.status && error.status !== 200 && !throwError) {
      return defaultResponse;
    }

    throw error;
  }
};

export const getCategoriesProps = async ({ req, res, slug, tab }) => {
  const cookies = new Cookies(req, res);
  const zipcode = cookies.get(COOKIE_VARIABLES.zipcode) || DEFAULT_ZIPCODE;
  const latitude = cookies.get(COOKIE_VARIABLES.latitude) || DEFAULT_LOCATION.lat;
  const longitude = cookies.get(COOKIE_VARIABLES.longitude) || DEFAULT_LOCATION.lng;
  const placeId = cookies.get(COOKIE_VARIABLES.placeId) || '';

  const host = req?.headers?.host === 'localhost:3000'
    ? process.env.NEXT_PUBLIC_FALLBACK_API_URL
    : req?.headers?.host;
  const zoneId =
    process.env.NEXT_PUBLIC_ENV === 'development' ? DEFAULT_ZONE_ID : cookies.get(COOKIE_VARIABLES.zoneId) || DEFAULT_ZONE_ID;

  const isDealsPage = (isDealsMerchandisingApplicable && slug === "deals")

  const deliverDetailsResponse = await fetchServerResponse({
    url: `${process.env.NEXT_PUBLIC_SECURE_EC2_URL}/pre_fetch/delivery_details/${zipcode}`,
    method: 'GET',
    headers: { 'x-origin': host, zc: zipcode, placeId, lat: latitude, lng: longitude, 'saas-domain': host }
  });

  const { lastSlotCutOff, asapEndTime, asapStartTime, asapEnabled, asap_driver_id } = deliverDetailsResponse.data || {};

  const currentTab = tab || getDefaultTab({ lastSlotCutOff, asapEndTime, asapStartTime, asapEnabled });

  const filterString = '?sort_key=deals_first&order=asc';
  let url = '';
  let bestSellersUrl = '';
  if (currentTab === ASAP) {
    url = `${process.env.NEXT_PUBLIC_SECURE_EC2_URL}/full_shop_category/asap/${slug}/${zipcode}`;
    if (
      process.env.NEXT_PUBLIC_APP === WEBSITES.GRASSDOOR ||
      process.env.NEXT_PUBLIC_DEFAULT_SITES
    ) {
      url += isDealsPage ? '?sort_key=product_price&order=asc' : '?sort_key=deals_first&order=asc';
    }
    if (asap_driver_id) {
      url += `&asap_driver_id=${asap_driver_id}`
    }
    bestSellersUrl = `${process.env.NEXT_PUBLIC_PRODUCT_RECOMMENDATIONS_URL}/recommend/top-sellers/${slug}/asap/${zoneId}?&${filterString}`;
  } else {
    url = `${process.env.NEXT_PUBLIC_SECURE_EC2_URL}/full_shop_category/schedule/${slug}/${zipcode}`;
    if (
      process.env.NEXT_PUBLIC_APP === WEBSITES.GRASSDOOR ||
      process.env.NEXT_PUBLIC_DEFAULT_SITES
    ) {
      url += isDealsPage ? '?sort_key=product_price&order=asc' : '?sort_key=deals_first&order=asc';
    }
    bestSellersUrl = `${process.env.NEXT_PUBLIC_PRODUCT_RECOMMENDATIONS_URL}/recommend/top-sellers/${slug}/schedule/${zoneId}?${filterString}`;
  }

  if (isDealsPage) {
    url += '&without_category=1'
  }


  try {
    const productsResponse = await fetchServerResponse({
      url,
      method: 'GET',
      headers: { 'x-origin': host, zc: zipcode, placeId, lat: latitude, lng: longitude, 'saas-domain': host },
      throwError: true
    });
    const bestSellersResponse = isBestSellerApplicable
      ? await fetchServerResponse({
        url: bestSellersUrl,
        method: 'GET',
        headers: { 'x-origin': host, zc: zipcode, placeId, lat: latitude, lng: longitude, 'saas-domain': host },
        defaultResponse: []
      })
      : [];
    const filterResponse = await fetchServerResponse({
      url: `${process.env.NEXT_PUBLIC_SECURE_EC2_URL}/categories/all_categories/'90001'?category_slug=${slug}`,
      method: 'GET',
      headers: { 'x-origin': host, zc: zipcode, placeId, lat: latitude, lng: longitude, 'saas-domain': host }
    });
    const bannerSeoResponse = await fetchServerResponse({
      url: `${process.env.NEXT_PUBLIC_SECURE_EC2_URL}/cms/carousel_details`,
      method: 'GET',
      headers: { 'x-origin': host, zc: zipcode, placeId, lat: latitude, lng: longitude, 'saas-domain': host }
    });
    const fullCategoriesListResponse = await fetchServerResponse({
      url: `${process.env.NEXT_PUBLIC_SECURE_EC2_URL}/pre_fetch/logo`,
      method: 'GET',
      headers: { 'x-origin': host, zc: zipcode, placeId, lat: latitude, lng: longitude, 'saas-domain': host }
    });

    const asapBundleResponse = currentTab === ASAP
      ? await fetchServerResponse({
        url: `${process.env.NEXT_PUBLIC_SECURE_EC2_URL}/products/bundle/${zipcode}?sort_key=deals_first&order=asc`,
        method: 'GET',
        headers: { 'x-origin': host, zc: zipcode, lat: latitude, lng: longitude, 'saas-domain': host }
      })
      : null;

    let asapCategories = null;
    let asapBundles = null;
    let asapPopularCategories = null;

    const asapUniqueProductsCount = currentTab === ASAP ? productsResponse?.data?.asap_product_count : null;
    asapPopularCategories = currentTab === ASAP
      ? categoriesHelper(bestSellersResponse?.data?.categories || [], true, true)
      : null;

    if (isDealsPage) {
      // On deals page we need products in single array
      asapCategories = productsResponse?.data?.categories || []
      asapBundles = asapBundleResponse?.data?.bundles || []
      asapPopularCategories = currentTab === ASAP ? (bestSellersResponse?.data?.categories || []) : null
    } else {
      // On rest of the pages we need products categorized
      const tempAsapCategories = currentTab === ASAP ? categoriesHelper(productsResponse?.data?.categories || []) : null;
      asapPopularCategories = currentTab === ASAP
        ? categoriesHelper(bestSellersResponse?.data?.categories || [], true, true)
        : null;
      if (tempAsapCategories?.length) {
        // Only 4 categories, with  5 products per category allowed, else page breaks due to heavy server side operation
        asapCategories = tempAsapCategories.slice(0, 4);
        asapCategories.forEach(element => {
          element.products = element.products.slice(0, 5);
        });
      }
    }

    let scheduleCategories = null;
    let schedulePopularCategories = null;

    const scheduleUniqueProductsCount = currentTab === SCHEDULE ? productsResponse?.data?.schedule_product_count : null;
    schedulePopularCategories = currentTab === SCHEDULE
      ? categoriesHelper(bestSellersResponse?.data?.categories || [], true, true)
      : null;

    if (isDealsPage) {
      // On deals page we need products in single array
      scheduleCategories = productsResponse?.data?.categories || []
      schedulePopularCategories = currentTab === SCHEDULE ? bestSellersResponse?.data?.categories || [] : null;
    } else {
      // On rest of the pages we need products categorized
      const tempScheduleCategories = currentTab === SCHEDULE ? categoriesHelper(productsResponse?.data?.categories || []) : null;
      schedulePopularCategories = currentTab === SCHEDULE
        ? categoriesHelper(bestSellersResponse?.data?.categories || [], true, true)
        : null;

      if (tempScheduleCategories?.length) {
        // Only 4 categories, with  5 products per category allowed, else page breaks due to heavy server side operation
        scheduleCategories = tempScheduleCategories.slice(0, 4);
        scheduleCategories.forEach(element => {
          element.products = element.products.slice(0, 5);
        });
      }
    }

    const asapCategoryDetails = currentTab === ASAP ? productsResponse?.data?.categoryDetails : null;
    const scheduleCategoryDetails = currentTab === SCHEDULE ? productsResponse?.data?.categoryDetails : null;

    const initialDeliveryAddressDetails = deliverDetailsResponse?.data;
    const asapEnabled = initialDeliveryAddressDetails?.asapEnabled || null;
    const scheduleDetails = initialDeliveryAddressDetails?.scheduleDetails || null;

    const categoryUrl = getCategoryUrl(slug);

    if (currentTab === ASAP && (!asapEnabled || !asapCategories?.length)) {
      return {
        redirect: {
          destination: `${categoryUrl}?tab=${SCHEDULE}`,
          permanent: false
        }
      };
    }

    if (currentTab === SCHEDULE && !scheduleDetails?.length) {
      return {
        redirect: {
          destination: `${categoryUrl}?tab=${ASAP}`,
          permanent: false
        }
      };
    }

    const totalAsapProductCount = asapCategories?.reduce((total, nextCategory) => {
      if (nextCategory?.products?.length) {
        return total + nextCategory.products.length;
      }
      return total;
    }, 0);

    const totalScheduleProductCount = scheduleCategories?.reduce((total, nextCategory) => {
      if (nextCategory?.products?.length) {
        return total + nextCategory.products.length;
      }
      return total;
    }, 0);

    let noIndex = false;
    // if (currentTab === SCHEDULE) {
    //   if (totalScheduleProductCount <= 2) {
    //     noIndex = true;
    //   }
    // } else if (totalAsapProductCount <= 2) {
    //   noIndex = true;
    // }

    const productStrainTypes = categoriesHelper(filterResponse?.data?.product_strain_types || []);
    const brands = categoriesHelper(filterResponse?.data?.brands || []);
    const deviceTypes = filterResponse?.data?.device_types || [];
    const fullShopCategories = categoriesHelper(filterResponse?.data?.full_shop_categories || []);

    const filterOptions = { productStrainTypes, brands, deviceTypes, isDealsPage }
    if (isDealsPage) {
      filterOptions.fullShopCategories = fullShopCategories
    }

    const filterList = generateFilters(filterOptions);
    const bannerData = bannerSeoResponse?.data?.data?.banner_images || {};
    const fullCategoriesList = fullCategoriesListResponse?.data || [];

    return {
      // will be passed to the page component as props
      props: {
        asapBundles,
        asapCategories,
        scheduleCategories,
        asapPopularCategories,
        schedulePopularCategories,
        filterList,
        bannerData,
        noIndex,
        asapCount: asapUniqueProductsCount,
        scheduleCount: scheduleUniqueProductsCount || 0,
        asapCategoryDetails,
        scheduleCategoryDetails,
        id: String(slug),
        key: String(slug),
        fullCategoriesList,
        initialDeliveryAddressDetails
      }
    };
  } catch (error) {
    res.statusCode = 404;
    return { props: { error: 404, id: String(slug), key: String(slug) } };
  }
};

export default fetchServerResponse;
